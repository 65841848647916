:root {
  --primary-color: #a557f9;
}

@font-face {
  font-family: "Bely Display W00 Regular";
  src: url('../public/bely.ttf')format("truetype");
}

@font-face {
  font-family: "FSP DEMO - Altivo Black";
  src: url('../public/altivo.ttf')format("truetype"); 
}

body {
  margin: 0;
  font-family: "Bely Display W00 Regular";
  font-style: normal;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
}

p {
  font-size: 10px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: italic;
  font-weight: 300;
}

h1 {
  font-family: "Bely Display W00 Regular";
  font-style: normal;
  font-weight: 800;
}

h1 h2 h3 h4 h5 h6 {
  font-family: "Bely Display W00 Regular";
}

p a {
  font-family: "Bely Display W00 Regular";
  font-style: italic;
  font-weight: 600;
}