.home {
}

.marquee__rtl {
    overflow: hidden; /* important */
    position: relative;
    height: 6rem;
    display: flex;
    width: fit-content;
    justify-content: space-between;
}

.marquee__rtl > div {
    animation: defilement-rtl 15s infinite linear; /* défilement */
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    /*padding-right: 105px;$/
    /*padding-left: 105px;*/
    height: 4rem;
    width: fit-content;
}
.marquee__rtl:hover > div {
    animation-play-state: paused; /* met en pause le défilement */
}

.marquee__rtl > div a {
    text-decoration: none;
    color: #fa6;
    font-size: 4rem;
}

.marquee__rtl > div p {
    padding-left: 0.2rem;
    padding-right: 0.2rem;
    color: #fa6;
    font-size: 4rem;
}

.logo__anim {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
}

.logo__anim img {
    width: 300px;
}

@keyframes defilement-rtl {
    0% {
        -webkit-transform: translate(0);
        transform: translate(0);
    }
    100% {
        -webkit-transform: translate(-100%);
        transform: translate(-100%);
    }
}


@media (max-width: 768px) {
    .marquee__rtl > div a {
        color: #fa6;
        font-size: 2rem;
    }
    
    .marquee__rtl > div p {
        color: #fa6;
        font-size: 2rem;
    }
}