.card_rtes {
    width: 100%;
    height: 100%;
    border-radius: 0.25rem;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    margin: 1rem;
    text-decoration: none;
    color: black;
    font-size: 12px;
    flex: 1 0 33%;
}

.card {
    width: 100%;
    height: 100%;
    border-radius: 0.25rem;
    
    margin: 1rem;
    color: black;
    font-size: 12px;
    flex: 1 0 33%;
}

.postImg {
    /*
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    */
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 12px;
    background-color: white;
    -webkit-box-shadow: 0px 8px 5px -4px #3d3d3d; 
    box-shadow: 0px 8px 5px -4px #3d3d3d;
}

.card-container {
    padding: 0.5rem 2rem;
    position: relative;
}

.card-body {
    display: none;
}

.card-body p {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: italic;
    font-weight: 400;
}

.card-container:hover .card-body {
    display: initial;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0.5rem 2rem;
    color: white;
}

.card-container:hover .card-body p {
    background-color: rgb(0 0 0 / 38%);
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    padding: 0.5rem 2rem;
}

@media (max-width: 768px) {
    .card-container:hover .card-body {
        display: none;
    }

    .card {
        margin: 0rem;
    }

    .card-container {
        padding: 0px;
        position: relative;
        box-sizing: border-box;
        padding-right: 0.2rem;
    }
}