.cart {
    margin-top: 2rem;
    position: relative;
    display: grid;
}

.cart__item {
    border-right: 0.5px solid black;
    border-bottom: 0.5px solid black;
    display: flex;
    flex-direction: column;
}

.order__item {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.order__item div {
    position: absolute;
    bottom: 0px;
    left: 0px;
    background-color: white;
    padding: 0.2rem;
    border-top: 0.5px solid black;
    border-right: 0.5px solid black;
}

.item__cart__img {
    object-fit: cover;
    width: 100%;
    padding: 0.5px;
}

.item__order__img {
    object-fit: cover;
    height: 100%;
    padding: 0.5px;
}

.cart__description {
    padding: 8px 8px;
    display: flex;
    justify-content: space-between;
}

.cart__action {
    --zds-quantity-selector-icon-size: 12px;
    align-items: center;
    display: flex;
    height: 32px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 16px 8px 8px;
}

.cart__action div:nth-child(1) {
    border: 0.5px solid black;
    padding: 8px;
    height: 100%;
    cursor: pointer;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.cart__action div:nth-child(2) {
    border-top: 0.5px solid black;
    border-bottom: 0.5px solid black;
    padding: 8px;
    height: 100%;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.cart__action div:nth-child(3) {
    border: 0.5px solid black;
    padding: 8px;
    height: 100%;
    cursor: pointer;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.cart__footer {
    position: fixed;
    bottom: 0px;
    background-color: white;
    border-top: 0.5px solid black;
    margin: 0;
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    z-index: 2;
}

.cart__action div {
    box-sizing: border-box;
}

.cart__footer > div:nth-child(3) {
    color: white;
    background-color: black;
    display: flex;
    height: 100%;
    min-height: 76px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    padding: 12px;
    text-decoration: none;
}

.cart__footer .go__to__order {
    color: white;
    background-color: black;
    display: flex;
    height: 100%;
    min-height: 76px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    padding: 12px;
    text-decoration: none;
}

.cart__remove {
    cursor: pointer;
}

.action__div {
    cursor: pointer;
}

.summary__main {
    display: flex;
    width: 100%;
    padding: 2rem;
    box-sizing: border-box;
}

.order__summary {
    width: 25vw;
    padding: 2rem;
    box-sizing: border-box;
}

.summary__information {
    width: 25vw;
    padding-right: 4rem;
    box-sizing: border-box;
}

.summary__infos {
    border: 0.5px solid black;
    padding: 1rem 2rem;
    box-sizing: border-box;
}

.summary__resume {
    width: 60vw;
}

.summary__container {
    border: 0.5px solid black;
    display: flex;
    overflow-x: scroll;
    height: 20vh;
    padding: 2rem 0rem;
}

.summary__checkout {
    display: flex;
    gap: 1rem;
    border: 0.5px solid black;
    padding: 1rem 2rem;
}

.summary__checkout div {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.empty__cart__message {
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cart__footer {
    grid-template-columns: 1fr 1fr auto;
    grid-template-rows: auto 1fr;
}

.cart__footer > div:nth-child(1) {
    grid-area: 2 / 1;
    display: flex;
    padding: 1rem;
    box-sizing: border-box;
    gap: 1rem;
    align-items: center;
}

.cart__footer div:nth-child(2) {
    grid-area: 2 / 2;
    justify-self: flex-end;
    margin-right: 48px;
    padding: 12px 8px 12px 12px;
}

.cart__footer .paypal__btn {
    grid-area: 2 / 3;
}

.cart__footer .paypal__btn input {
    background-color: transparent;
    border: none;
    color: white;
    width: 100%;
    height: 100%;
}

.cart__footer .paypal__btn {
    grid-area: 1 / 1 / 2 / 3;
}

.cart__footer .go__to__order {
    grid-area: 2 / 3;
}

.paypal__btn {
    position: relative;
}

.paypal__btn > div {
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.paypal__btn p {
    font-size: initial;
    font-family: initial;
    font-style: initial;
}

@media (min-width: 768px) {
    .cart{
        grid-template-columns: repeat(2, minmax(0, 1fr));
        width: calc(100% + 0.5px);
    }

    .cart__footer div:nth-child(3) {
        grid-area: 2 / 3;
    }
}

@media (min-width: 1024px) {
    .cart {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }
}


@media (max-width: 1024px) {
    .summary__main {
        flex-direction: column;
    }
    
    .order__summary {
        width: auto;
        padding: 2rem;
        box-sizing: border-box;
    }
    
    .summary__information {
        width: auto;
        padding-right: 0rem;
        box-sizing: border-box;
    }
    
    .summary__resume {
        width: auto;
    }
}