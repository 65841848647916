.actu {
    background-image: url("../../public/Fond\ vague.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center, 50%, 50%;
    padding: 5rem;
    min-height: 1000px;
    position: relative;
}

.actu .tittle {
    width: 30%;
}

.actu .tittle img {
    width: 100%;
}

.actu .tittle h1 {
    margin-block-start: 0;
    margin-block-end: 0;
    color: #fff;
    font-size: 5rem;
    font-weight: bold;
    font-family: "FSP DEMO - Altivo Black";
}

.actu .container {
    margin-top: 1.5rem;
    width: 100%;
    position: relative;
    z-index: 1;
    /*display: grid;*/
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
}

.actu .container div {
    /*width: 100%;
    aspect-ratio: 1 / 1;
    background-color: #fff;*/
}

.gems__actu {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    align-items: center;
    margin-top: 1rem;
    margin-right: 10rem;
}

.gems__actu img {
    width: 45.75px;
    height: 32.45px;
}

.first__ano {
    position: absolute;
    top: 400px;
    left: -400px;
    width: 1000px;
}

.actu__ano {
    right: -300px;
    position: absolute;
    top: -200px;
    width: 700px;
}

@media (max-width: 768px) {
    .actu {
        padding: 2rem;
        min-height: 100px;
    }
    
    .actu .tittle h1 {
        font-size: 2rem;
    }

    .gems__actu {
        display: none;
    }

    .first__ano {
        position: absolute;
        top: 100px;
        left: -200px;
        width: 500px;
    }
    
    .actu__ano {
        left: 300px;
        position: absolute;
        top: -0px;
        width: 200px;
    }    
}