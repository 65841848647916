.infos {
    padding: 5rem;
    background-color: var(--primary-color);
    position: relative;
}

.infos .tittle {
    width: 30%;
    position: relative;
    z-index: 1;
}

.infos .tittle img {
    width: 100%;
}

.infos .tittle h1 {
    margin-block-start: 0;
    margin-block-end: 0;
    color: #fff;
    font-size: 5rem;
    font-weight: bold;
    font-family: "FSP DEMO - Altivo Black";
}

.infos .container {
    color: white;
    padding-left: 8rem;
    padding-right: 8rem;
    font-size: 2rem;
    position: relative;
    z-index: 1;
}

.infos .container p {
    font-size: 2rem;
}

.gems {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    position: relative;
    z-index: 1;
}

.gems img {
    width: 45.75px;
    height: 32.45px;
}

.second__ano {
    position: absolute;
    top: 400px;
    left: 0px;
    width: 800px;
    transform: rotate(80deg);
}

.third__ano {
    position: absolute;
    top: -150px;
    right: -300px;
    width: 700px;
    transform: rotate(190deg);
}

.four__ano {
    position: absolute;
    top: -200px;
    left: -400px;
    width: 1000px;
}

.fifth__ano {
    position: absolute;
    bottom: 100px;
    right: 100px;
    width: 400px;
}

.six__ano {
    position: absolute;
    top: 100px;
    right: 40vw;
    width: 300px;
}

.product__slider {
    z-index: 1;
}

@media (max-width: 768px) {
    .infos {
        padding: 1rem;
    }

    .infos .container {
        padding-left: 4rem;
        padding-right: 4rem;
    }

    .infos .container h2 {
        font-size: 1.5rem;
    }

    .infos .container p {
        font-size: 1rem;
    }
    
    .infos .tittle h1 {
        font-size: 2rem;
    }

    .second__ano {
        right: 80px;
        left: initial;
        position: absolute;
        top: 150px;
        width: 300px;
        transform: rotate(80deg);
    }

    .third__ano {
        position: absolute;
        right: -140px;
        top: -110px;
        width: 350px;
        transform: rotate(190deg);
    }

    .four__ano {
        left: -120px;
        position: absolute;
        top: -120px;
        width: 300px;
    }

    .fifth__ano {
        bottom: 0px;
        position: absolute;
        right: 0px;
        width: 100px;
    }

    .six__ano {
        position: absolute;
        right: 40vw;
        top: 50px;
        width: 150px;
    }
}