.App {
  width: 100%;
  overflow: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.home__container {
  background: url("../public/fond.webp") no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  flex: 1 1 auto;
  margin-top: 70px;
}