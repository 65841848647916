.shop {
    padding: 0.5rem;
    position: relative;
}

.shop .tittle {
    width: fit-content;
    padding-left: 2rem;
    padding-right: 2rem;
    writing-mode: vertical-rl;
    text-orientation: upright;
    margin: auto;
    font-size: 2.3rem;
    width: 10%;
    display: flex;
    justify-content: center;
}

.shop .tittle h1 {
    margin-block-start: 0;
    margin-block-end: 0;
    color: var(--primary-color);
    font-family: "FSP DEMO - Altivo Black";
}

.shop .container {
    margin-top: 1.5rem;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(auto-fit, 1fr);
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    position: relative;
    z-index: 1;
}

.shop .container a {
    text-decoration: none;
    color: black;
}


.shop .container div {
    width: 100%;
    box-sizing: border-box;
}

.item__img__shop {
    width: 100%;
    aspect-ratio: 1 / 1.5;
    background-color: var(--primary-color);
    border-radius: 10%;
    background: round;
    -webkit-box-shadow: 0px 8px 5px -4px #3d3d3d; 
    box-shadow: 0px 8px 5px -4px #3d3d3d;
}

.item__img__shop:hover {
    box-sizing: border-box;
    border-radius: 10%;
    border: solid 4px;
    border-color: var(--primary-color);
}

.item__infos {
    padding-left: 1.5rem;
    margin-top: 0.5rem;
}

.item__infos h2, p {
    margin-block-start: 0;
    margin-block-end: 0;
}

.item__infos p {
    padding-left: 1rem;
    font-style: italic;
    font-size: larger;
}

.shop .place {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
}

.shop .place h1 {
    color: var(--primary-color);
    font-size: 3rem;
    margin-block-end: 0.2rem;
}

.shop .place div {
    border: 4px solid var(--primary-color);
    width: fit-content;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 0.2rem;
    border-radius: 12px;
    font-size: 1.8rem;
    margin-bottom: 2rem;
}

.shop .place div p {
    font-size: 2rem;
    font-style: italic;
    font-weight: normal;
}

.shop__container {
    display: flex;
    position: relative;
    z-index: 1;
}

.shop .place div a {
    text-decoration: none;
    color: black;
}

.shop .place div:hover {
    background-color: var(--primary-color);
    color: white;
}

.shop .place div a:hover {
    color: white;
}

.nine__ano {
    position: absolute;
    top: -200px;
    right: -200px;
    width: 600px;
}
.ten__ano {
    position: absolute;
    width: 800px;
    top: 600px;
    left: -200px;
}

.eleven__ano {
    position: absolute;
    width: 200px;
    top: 1000px;
    right: 0px;
}
.twelve__ano {
    position: absolute;
    width: 600px;
    top: 0px;
    left: -100px;
}
.tbis_ano {
    position: absolute;
    width: 900px;
    top: 300px;
    right: -200px;
}
.bthred__ano {
    position: absolute;
    width: 400px;
    top: 1300px;
    left: 0px;
}

.shop__tittle {
    display: initial;
    width: 100%;
    object-fit: contain;
}

.shop__tel__tittle {
    display: none;
}

@media (max-width: 1075px) {
    .shop .container {
        grid-template-columns: repeat(2, 1fr);
        padding: 0rem 0.4rem;
        box-sizing: border-box;
    }
}

@media (max-width: 768px) {
    .shop__tittle {
        display: none;
    }

    .shop__tel__tittle {
        display: initial;
        object-fit: contain;
        width: 100%;
        aspect-ratio: 21 / 9;
    }

    .item__infos h2 {
        font-size: 1rem;
    }

    .shop .container {
        grid-template-columns: repeat(2, 1fr);
        padding: 0rem 0.4rem;
        box-sizing: border-box;
    }

    .shop__container {
        display: initial;
    }

    .shop .tittle {
        width: fit-content;
        writing-mode: initial;
    }

    .shop .tittle__mobile {
        display: none;
    }

    .shop .place div {
        border: 2px solid var(--primary-color);
        font-size: 0.6rem;
    }

    .shop .place h1 {
        font-size: 1.5rem;
        font-family: "Bely Display W00 Regular";
        font-weight: 700;
        color: #fa6;
    }

    .nine__ano {
        position: absolute;
        right: -150px;
        top: -20px;
        width: 300px;
    }
    .ten__ano {
        left: -100px;
        position: absolute;
        top: 600px;
        width: 400px;
    }
    
    .eleven__ano {
        position: absolute;
        right: 0;
        top: 1100px;
        width: 200px;
    }
    .twelve__ano {
        left: 20%;
        position: absolute;
        top: 50px;
        width: 300px;
    }
    .tbis_ano {
        position: absolute;
        right: -100px;
        top: 300px;
        width: 450px;
    }
    .bthred__ano {
        left: 20%;
        position: absolute;
        top: 1150px;
        width: 200px;
    }

    .shop .tittle h1 {
        color: #70FAAA;
    }
}