.button {
    background-color: var(--primary-color);
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 12px;
    align-self: flex-start;
    margin-top: auto;
}

.product__container {
    background-image: url("../../public/fond.webp");
    background-size: cover;
    flex: 1 1 auto;
    margin-top: 70px;
}

.product {
    display: flex;
    padding: 4rem 2rem;
    gap: 2rem;
}

.product__img {
    width: 400px;
    height: 600px;
    border-radius: 12px;
}

.product__img img {
    width: 100%;
    border-radius: 12px;
}

.slider {
    border-radius: 12px;
}

.product__description {
    background-color: white;
    flex: 1;
    padding: 2rem;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
}

.product__description p {
    font-size: 1rem;
}

.product__selection {
    background-image: url("../../public/Fond\ vague.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center, 50%, 50%;
    height: 600px;
    position: relative;
    z-index: 1;
}

.card__product {
    width: 100%;
    height: 100%;
    border-radius: 0.25rem;
    
    margin: 1rem;
    color: black;
    font-size: 12px;
    flex: 1 0 33%;
}

.card__container__product {
    padding: 0.5rem 2rem;
}

.item__img {
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 10%;
    background-color: white;
}

.seven_ano {
    position: absolute;
    top: -100px;
    width: 1000px;
    left: -300px;
}

.eight__ano {
    position: absolute;
    top: -100px;
    right: -200px;
    width: 1000px;
}

.confirm {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background-color: #89838378;
    display: flex;
    z-index: 11;
}

.confirm__container {
    background-color: white;
    width: 20vw;
    padding: 16px;
    box-sizing: border-box;
}

.confirm__infos {
    display: flex;
    width: 100%;
    gap: 2%;
}

.confirm__infos img {
    width: 40%;
}

.confirm__close {
    display: flex;
    justify-content: flex-end;
}

.confirm__action {
    display: flex;
    justify-content: center;
    margin-top: 2%;
}

.confirm__close__zone {
    flex: 1 auto;
}

.add__cart {
    margin-top: auto;
}

.sizeContainer {
    display: flex;
    justify-content: space-evenly;
    text-align: center;
    padding: 10px;
}

.ui--dot-item.is-dot {
    min-width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    border: 1px solid #b4b4b4;
    border-radius: 0.25rem;
    background-color: #fff;
}

.ui--dot-item.is-dot:hover {
    border: 1px solid var(--primary-color);
}

.ui--dot-item.is-selected {
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color);
}

.ui--dot-item .text {
    font-size: .875rem;
    line-height: .875rem;
    font-weight: 400;
    text-align: center;
    display: flex;
    align-items: center;
    vertical-align: middle;
    justify-content: center;
}


.ui--dot-item span {
    font-style: inherit;
    font-weight: inherit;
    color: #000 !important;
}

.ui--dot-item.is-selected span {
    color: #fff !important;
}

[dir=ltr] .ui--dot-item .text,
[dir=rtl] .ui--dot-item .text {
    padding: 0 10px;
}

@media (max-width: 768px) {
    .product__selection {
        align-content: center;
    }

    .product {
        flex-direction: column;
    }

    .product__img {
        width: auto;
        height: auto;
    }

    .confirm__container {
        width: 70vw;
    }

    .add__cart {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1rem;
    }

    .seven_ano {
        position: absolute;
        top: 200px;
        width: 600px;
        left: -200px;
    }
    
    .eight__ano {
        position: absolute;
        top: -100px;
        right: -200px;
        width: 500px;
    }
}