footer {
    padding: 6rem;
    position: relative;
    z-index: 1;
}

.contact {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2rem;
    gap: 0.5rem;
}

.contact img {
    width: 70px;
    object-fit: contain;
}

.contact a {
    text-decoration: none;
    color: black;
}

.legal {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}

.legal a {
    text-decoration: none;
    color: black;
    cursor: pointer;
}