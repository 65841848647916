header {
    background-color: var(--primary-color);
    height: 70px;
    width: calc(100vw - 8rem);
    display: flex;
    align-items: center;
    padding: 0 4rem;
    position: fixed;
    -webkit-box-shadow: 0px 2px 15px 0px #000000;
    box-shadow: 0px 2px 15px 0px #000000;
    z-index: 10;
}

.header__blank {
    width: 67px;
}

header img {
    height: 48px;
    width: 67px;
}

.header__logo {
    flex: 1;
    text-align: center;
    height: 100%;
    align-content: center;
}

@media (max-width: 768px) {
    header{
        width: calc(100vw - 2rem);
        padding: 0 1rem;
    }
}